import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";

const routes: Routes = [
    {
        path: "account",
        component: AuthLayoutComponent,
        loadChildren: () =>
            import("./modules/account/account-routing.module").then(
                (m) => m.AccountRoutingModule
            ),
    },
    {
        path: "",
        component: MainLayoutComponent,
        loadChildren: () =>
            import("./features/dashboard/dashboard.module").then(
                (m) => m.DashboardModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: "stabilimento",
        component: MainLayoutComponent,
        loadChildren: () =>
            import("./features/stabilimento/stabilimento.module").then(
                (m) => m.StabilimentoModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: "situazione-spiaggia",
        component: MainLayoutComponent,
        loadChildren: () =>
            import("./features/ricevimento/ricevimento.module").then(
                (m) => m.RicevimentoModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: "impostazioni",
        component: MainLayoutComponent,
        loadChildren: () =>
            import("./features/impostazioni/impostazioni.module").then(
                (m) => m.ImpostazioniModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: "lista-prenotazioni",
        component: MainLayoutComponent,
        loadChildren: () =>
            import(
                "./features/lista-prenotazioni/lista-prenotazioni.module"
            ).then((m) => m.ListaPrenotazioniModule),
        canActivate: [AuthGuard],
    },

    { path: "**", redirectTo: "" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
