import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountStorageService } from "src/app/core/services";

@Component({
    selector: "og-main-layout",
    templateUrl: "./main-layout.component.html",
    styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
    initial: string = "";
    nomeStabilimento: string = "";

    // private menu: NavItem[] = menu;

    constructor(
        private router: Router,
        private accountStorageService: AccountStorageService
    ) {}

    ngOnInit(): void {
        const user = this.accountStorageService.getUser();

        this.initial =
            user.firstName.charAt(0).toUpperCase() +
            user.lastName.charAt(0).toUpperCase();

            this.nomeStabilimento = this.accountStorageService.getSelectedBeachName()!;
    }

    logout() {
        this.accountStorageService.logout();

        this.router.navigate(["account/login"]);
    }
}
