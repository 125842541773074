<div style="background-color: #eef0f8; min-height: 100vh; padding-top: 10%">
    <div class="d-flex justify-content-center mb-4">
        <img src="assets/logo-ombrellone.png" alt="" style="max-height: 60px" />
    </div>
    <mat-card class="box">
        <h2 class="mb-0">Accedi su Ombrellone.it</h2>
        <h5>Inserisci i tuoi dati per accedere al tuo account:</h5>
        <form [formGroup]="form">
            <pre>{{ error }}</pre>
            <mat-card-content>
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Email"
                        formControlName="email"
                    />
                </mat-form-field>

                <mat-form-field>
                    <input
                        matInput
                        type="password"
                        placeholder="Password"
                        formControlName="password"
                    />
                </mat-form-field>
            </mat-card-content>
            <button mat-raised-button color="primary" (click)="login()">
                Accedi
            </button>
        </form>
    </mat-card>
</div>
