<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content [innerHTML]="data.message"></div>

<mat-dialog-actions align="end">
    <button class="mat-raised-button" [mat-dialog-close]="false">Cancel</button>

    <button
        class="ms-2 mat-raised-button mat-primary"
        [mat-dialog-close]="true"
    >
        Confirm
    </button>
</mat-dialog-actions>
