import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PrenotazioneService } from "./services/prenotazione/prenotazione.service";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [PrenotazioneService],
})
export class CoreModule {}
