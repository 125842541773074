import { Injectable } from "@angular/core";
import { AccountData } from "./account-data";
import { SelectedBeach } from "./selected-beach";
import { User } from "./user";

@Injectable({
    providedIn: "root",
})
export class AccountStorageService {
    private KEY = "accout_data";

    constructor() {}

    create(
        token: string,
        user_id: string,
        user_email: string,
        firstName: string,
        lastName: string,
        selected_beach_id?: number,
        selected_beach_name?: string,
        selected_beach_map?: string
    ): void {
        let accountData = {} as AccountData;

        accountData.token = token;
        accountData.user = {} as User;
        accountData.user.id = user_id;
        accountData.user.email = user_email;
        accountData.user.firstName = firstName;
        accountData.user.lastName = lastName;

        if (selected_beach_id) {
            accountData.selected_beach = {
                id: selected_beach_id!,
                name: selected_beach_name!,
                map: selected_beach_map!,
            } as SelectedBeach;
            // accountData.selected_beach.id = selected_beach_id!;
            // accountData.selected_beach.name = selected_beach_name!;
        }

        localStorage.setItem(this.KEY, JSON.stringify(accountData));
    }

    get(): AccountData | null {
        return localStorage.getItem(this.KEY) !== null
            ? <AccountData>JSON.parse(localStorage.getItem(this.KEY) || "{}")
            : null;
    }

    getUser(): User {
        const account = this.get();

        return account?.user!;
    }

    getSelectedBeachId(): number | null {
        const account = this.get();

        return account?.selected_beach?.id!;
    }

    getSelectedBeachName(): string | null {
        const account = this.get();

        return account?.selected_beach?.name!;
    }

    getSelectedBeachMap(): string {
        const account = this.get();

        return account?.selected_beach?.map !== ""
            ? account?.selected_beach?.map!
            : "assets/images/merli-beach-mappa-fondo.png";
    }

    setToken(token: string) {
        let currentAccountData = this.get();

        if (currentAccountData != null) {
            currentAccountData.token = token;

            localStorage.setItem(this.KEY, JSON.stringify(currentAccountData));
        } else throw new Error("Unable to update token");
    }

    setUser(id: string, email: string, firstName: string, lastName: string) {
        let currentAccountData = this.get();

        if (currentAccountData != null) {
            let user = {} as User;

            user.id = id;
            user.email = email;
            user.firstName = firstName;
            user.lastName = lastName;

            currentAccountData.user = user;

            localStorage.setItem(this.KEY, JSON.stringify(currentAccountData));
        } else throw new Error("Unable to update user");
    }

    setSelectedBeachId(id: number, name: string, map: string) {
        let currentAccountData = this.get();

        if (currentAccountData != null) {
            currentAccountData.selected_beach = {
                id: id,
                name: name,
                map: map,
            } as SelectedBeach;

            localStorage.setItem(this.KEY, JSON.stringify(currentAccountData));
        } else throw new Error("Unable to update selected beach");
    }

    logout(): void {
        localStorage.removeItem(this.KEY);
    }
}
