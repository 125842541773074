import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { concatMap, map, of } from "rxjs";
import { AccountStorageService, StabilimentoService } from "src/app/core/services";

import { AuthService } from "../../services/auth.service";

@Component({
    selector: "og-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    error: string = "";

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private accountStorageService: AccountStorageService,
        private stabilimentoService: StabilimentoService,
        private router: Router
    ) {
        // this.form = this.fb.group({
        //     email: ["leonardo.maria.miliacca@gmail.com", Validators.required],
        //     password: ["abcdefgh", Validators.required],
        // });

        this.form = this.fb.group({
            email: ["", Validators.required],
            password: ["", Validators.required],
        });
    }

    ngOnInit(): void {}

    login() {
        if (this.form.valid) {
            const formValue = this.form.value;

            this.authService
                .login(formValue.email, formValue.password)
                .pipe(
                    map((response) => {
                        this.accountStorageService.create(
                            response.token,
                            response.user.id,
                            response.user.email,
                            response.user.nome,
                            response.user.cognome
                        );

                        return response;
                    }),
                    concatMap((response) => {
                        return this.stabilimentoService.getStabilimentiByUtente(
                            response.user.id
                        );
                    }),
                    concatMap((response) => {
                        if (response.payload.length > 0) {
                            this.accountStorageService.setSelectedBeachId(
                                response.payload[0].id,
                                response.payload[0].nome,
                                response.payload[0].map_url,
                            );
                        }

                        return of(response);
                    })
                )
                .subscribe({
                    next: (response) => {
                        this.router.navigate(["/reception/reservations"]);
                    },
                    error: (e) => (this.error = e.error.message),
                    // complete: () => console.info("complete"),
                });
        }
    }
}
