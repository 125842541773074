import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { MaterialModule } from "./modules/material.module";
import { AccountModule } from "./modules/account/account.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";

import { ModalConfirmComponent } from "./components/modal-confirm/modal-confirm.component";
import { ModalHttpErrorResponseComponent } from "./components/modal-http-error-response/modal-http-error-response.component";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";

import { NativeDateAdapter } from "@angular/material/core";
import { MatDateFormats } from "@angular/material/core";
import { CoreModule } from "./core/core.module";
import { formatDate, registerLocaleData } from "@angular/common";

import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it');

export class AppDateAdapter extends NativeDateAdapter {
    override format(date: Date, displayFormat: Object): string {
        if (displayFormat === "input") {
            // return new DatePipe("it-IT").transform(date, 'shortDate');
            let day: string = date.getDate().toString();
            day = +day < 10 ? "0" + day : day;
            let month: string = (date.getMonth() + 1).toString();
            month = +month < 10 ? "0" + month : month;
            let year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return date.toDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: "short", year: "numeric", day: "numeric" },
    },
    display: {
        dateInput: "input",
        monthYearLabel: { year: "numeric", month: "numeric" },
        dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
        monthYearA11yLabel: { year: "numeric", month: "long" },
    },
};

class PickDateAdapter extends NativeDateAdapter {
    override format(date: Date, displayFormat: Object): string {
        if (displayFormat === "input") {
            return formatDate(date, "dd-MMM-yyyy", this.locale);
        } else {
            return date.toDateString();
        }
    }
}

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        MainLayoutComponent,
        ModalConfirmComponent,
        ModalHttpErrorResponseComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        MaterialModule,
        AccountModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        // { provide: DateAdapter, useClass: AppDateAdapter },
        // { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },

        // { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
        {provide: LOCALE_ID, useValue: "it" }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
