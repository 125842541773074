import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { DashboardPrenotazione } from "../../models";
import { BaseResponse } from "../base-response";
import { DashboardResponse } from "./dashboard-response";

@Injectable({
    providedIn: "root",
})
export class DashboardService {
    constructor(private http: HttpClient) {}

    getPrenotazioni(): Observable<BaseResponse<DashboardResponse>> {
        const URI = `${environment.API_URL}/prenotazione/dashboard`;

        return this.http.get<BaseResponse<DashboardResponse>>(URI);
        // .pipe(
        //     map((response) =>
        //         response.payload.nonShown.map(
        //             (item: DashboardPrenotazioneResponse) => {
        //                 const dtInizio = new Date(item.data_inizio);

        //                 return {
        //                     id: item.id,
        //                     data_inizio: new Date(dtInizio.getUTCFullYear(), dtInizio.getUTCMonth(), dtInizio.getUTCDate()),
        //                     cognome: item.user.cognome,
        //                     nome: item.user.nome,
        //                 } as DashboardPrenotazione;
        //             }
        //         )
        //     )
        // );
    }
}
