import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Ombrellone } from "../../models/ombrellone";
import { ServizioStabilimento } from "../../models/servizio-stabilimento";
import { Stabilimento } from "../../models/stabilimento";
import { BaseResponse } from "../base-response";
import { ClienteStabilimentoResponse } from "./cliente-stabilimento-response";
import { ImmagineStabilimentoResponse } from "./immagine-stabilimento-response";
import { PrenotabilitaResponse } from "./prenotabilita-response";

@Injectable({
    providedIn: "root",
})
export class StabilimentoService {
    constructor(private http: HttpClient) {}

    getStabilimentiByUtente(
        idUtente: string
    ): Observable<BaseResponse<Stabilimento[]>> {
        const URI = `${environment.API_URL}/user/${idUtente}/stabilimento`;

        return this.http.get<BaseResponse<Stabilimento[]>>(URI);
    }

    getStabilimento(id: number): Observable<BaseResponse<Stabilimento>> {
        const URI = `${environment.API_URL}/stabilimento/${id}`;

        return this.http.get<BaseResponse<Stabilimento>>(URI);
    }

    updateStabilimento(
        stabilimento: Stabilimento
    ): Observable<BaseResponse<Stabilimento>> {
        const URI = `${environment.API_URL}/stabilimento/${stabilimento.id}`;

        return this.http.patch<BaseResponse<Stabilimento>>(URI, stabilimento);
    }

    getOmbrelloniStabilimento(
        id: number
    ): Observable<BaseResponse<Ombrellone[]>> {
        const URI = `${environment.API_URL}/stabilimento/${id}/ombrellone`;

        return this.http.get<BaseResponse<Ombrellone[]>>(URI);
    }

    getServiziStabilimento(
        idStabilimento: number
    ): Observable<BaseResponse<ServizioStabilimento[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/servizio`;

        return this.http.get<BaseResponse<ServizioStabilimento[]>>(URI);
    }

    createServizioStabilimento(
        idStabilimento: number,
        idServizio: number
    ): Observable<BaseResponse<ServizioStabilimento[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/servizio/${idServizio}`;

        return this.http.post<BaseResponse<ServizioStabilimento[]>>(URI, {});
    }

    deleteServizioStabilimento(
        idStabilimento: number,
        idServizio: number
    ): Observable<BaseResponse<ServizioStabilimento[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/servizio/${idServizio}`;

        return this.http.delete<BaseResponse<ServizioStabilimento[]>>(URI);
    }

    getPrenotabilitaStabilimento(
        idStabilimento: number,
        dataInizio: string,
        dataFine: string
    ): Observable<BaseResponse<PrenotabilitaResponse[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/prenotabilita`;

        return this.http.post<BaseResponse<PrenotabilitaResponse[]>>(URI, {
            data_da: dataInizio,
            data_a: dataFine,
        });
    }

    getMappaStabilimento(
        idStabilimento: number
    ): Observable<BaseResponse<string>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/map`;

        return this.http.get<BaseResponse<string>>(URI);
    }

    updateMappaStabilimento(
        idStabilimento: number,
        file: File
    ): Observable<BaseResponse<Stabilimento>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/map`;

        return this.http.post<BaseResponse<Stabilimento>>(URI, formData);
    }

    deleteMappaStabilimento(
        idStabilimento: number
    ): Observable<BaseResponse<ServizioStabilimento[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/map`;

        return this.http.delete<BaseResponse<ServizioStabilimento[]>>(URI);
    }

    getImmaginiStabilimento(
        idStabilimento: number
    ): Observable<BaseResponse<ImmagineStabilimentoResponse[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/upload`;

        return this.http.get<BaseResponse<ImmagineStabilimentoResponse[]>>(URI);
    }

    uploadImmagineStabilimento(
        idStabilimento: number,
        file: File,
        ordine: number
    ): Observable<BaseResponse<any>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/upload?order=${ordine}`;

        return this.http.post<BaseResponse<any>>(URI, formData);
    }

    deleteImmagineStabilimento(
        idStabilimento: number,
        ordine: number
    ): Observable<BaseResponse<any>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/upload?order=${ordine}`;

        return this.http.delete<BaseResponse<any>>(URI);
    }

    getClientiStabilimento(
        idStabilimento: number
    ): Observable<BaseResponse<ClienteStabilimentoResponse[]>> {
        const URI = `${environment.API_URL}/stabilimento/${idStabilimento}/cliente`;

        return this.http.get<BaseResponse<ClienteStabilimentoResponse[]>>(URI);
    }
}
