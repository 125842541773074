import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AccountRoutingModule } from "./account-routing.module";
import { LoginComponent } from "./pages/login/login.component";
import { MaterialModule } from "../material.module";

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        AccountRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
    ],
})
export class AccountModule {}
