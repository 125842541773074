import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { AccountStorageService } from "../core/services";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);

    constructor(private accountStorageService: AccountStorageService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const account = this.accountStorageService.get();
        const isLoggedIn = account?.token;
        const isApiUrl = request.url.startsWith(environment.API_URL);

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${account.token}` },
            });
        }

        return next.handle(request);

        // return next.handle(request).pipe(
        //     map((response) => {
        //         console.log(response);
        //         return response;
        //     })
        // );

        // return next.handle(request).pipe(
        //     catchError((error) => {
        //         if (
        //             error instanceof HttpErrorResponse &&
        //             !request.url.includes("account/login") &&
        //             error.status === 401
        //         ) {
        //             return this.handle401Error(request, next);
        //         }
        //         return throwError(error);
        //     })
        // );
    }

    // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    //     if (!this.isRefreshing) {
    //         this.isRefreshing = true;
    //         this.refreshTokenSubject.next(null);
    //         const token = "xxx"; // this.tokenService.getRefreshToken();
    //         if (token)
    //             return this.authService.refreshToken(token).pipe(
    //                 switchMap((token: any) => {
    //                     this.isRefreshing = false;
    //                     this.tokenService.saveToken(token.accessToken);
    //                     this.refreshTokenSubject.next(token.accessToken);

    //                     return next.handle(
    //                         this.addTokenHeader(request, token.accessToken)
    //                     );
    //                 }),
    //                 catchError((err) => {
    //                     this.isRefreshing = false;

    //                     this.tokenService.signOut();
    //                     return throwError(err);
    //                 })
    //             );
    //     }

    //     return this.refreshTokenSubject.pipe(
    //         filter((token) => token !== null),
    //         take(1),
    //         switchMap((token) =>
    //             next.handle(this.addTokenHeader(request, token))
    //         )
    //     );
    // }
}
