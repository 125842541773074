import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'og-modal-http-error-response',
  templateUrl: './modal-http-error-response.component.html',
  styleUrls: ['./modal-http-error-response.component.scss']
})
export class ModalHttpErrorResponseComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalHttpErrorResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HttpErrorResponse
) {
}

  ngOnInit(): void {
  }

}
