<h1 mat-dialog-title>Error</h1>

<div mat-dialog-content>
    <pre>{{ data | json }}</pre>
</div>

<mat-dialog-actions align="center">
    <button class="mat-raised-button mat-primary" [mat-dialog-close]="true">
        Ok
    </button>
</mat-dialog-actions>
