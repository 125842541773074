import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EstrattoConto } from "../../models/estratto-conto";
import { BaseResponse } from "../base-response";
import { NuovoEstrattoContoRequest } from "./nuovo-estratto-conto-request";

@Injectable({
    providedIn: "root",
})
export class EstrattoContoService {
    constructor(private http: HttpClient) {}

    createEstrattoConto(
        nuovoEstrattoConto: NuovoEstrattoContoRequest
    ): Observable<BaseResponse<EstrattoConto>> {
        const URI = `${environment.API_URL}/estratto_conto`;

        return this.http.post<BaseResponse<EstrattoConto>>(
            URI,
            nuovoEstrattoConto
        );
    }
}
