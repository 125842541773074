import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(private http: HttpClient) {}

    login(email: string, password: string): Observable<LoginResponse> {
        const URI = `${environment.API_URL}/auth/login`;

        return this.http.post<LoginResponse>(URI, { email, password });
    }

    refreshToken(): Observable<LoginResponse> {
        const URI = `${environment.API_URL}/auth/refresh`;

        return this.http.post<LoginResponse>(URI, null);
    }
}

interface LoginResponse {
    expires_in: number; // 4500
    message: string; //  "successfully logged in"
    status: string; // "success"
    token: string; // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDE4MjgyMTYsImlhdCI6MTY0MTgyMzcxNiwic3ViIjoiYjgxZjEwMWUtNTg0Zi00NjNjLWE4MTItZDU5ODY2NzhiZWM3In0.igoiLJc4rrlmT_OwdPVl3TcM1taEN5wXiCIpJCC2OUw"
    user: OMUser;
    token_type: string;
}

interface OMUser {
    id: string;
    email: string;
    rolw: string;
    id_stato_utente: number; // 0
    stato_utente: {
        descrizione: string;
        flag_ann: number; // 0
        note_record: string;
        data_creazione: string; // 2021-12-20T14:42:23.425Z;
        data_modifica: string; // 2021-12-20T14:42:23.425Z
    };
    id_tipo_utente: number; // 0
    tipo_utente: {
        descrizione: string;
        flag_ann: number; // 0
        note_record: string;
        data_creazione: string; // 2021-12-20T14:42:23.425Z;
        data_modifica: string; // 2021-12-20T14:42:23.425Z
    };
    nome: string;
    cognome: string;
    sesso: string;
    cellulare: string;
    indirizzo: string;
    codice_fiscale: string;
    partita_iva: string;
    indirizzo_fatturazione: string;
    email_paypal: string;
    riceve_newsletter: string;
    riceve_mail_automatiche: string;
    loggato: string;
    codice_utente: number; // 0
    codice_utente_padre: string;
    resetKey: string;
    privacy: number; // 0
    terms: number; // 0
    flag_ann: number; // 0
    data_creazione: string; // 2021-12-20T14:42:23.425Z;
    data_modifica: string; // 2021-12-20T14:42:23.425Z
}
