import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "og-modal-confirm",
    templateUrl: "./modal-confirm.component.html",
    styleUrls: ["./modal-confirm.component.scss"],
})
export class ModalConfirmComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ModalConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}
}
