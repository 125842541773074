import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Prenotazione } from "../../models/prenotazione";
import { BaseResponse } from "../base-response";
import { NuovaPrenotazioneRequest } from "./nuova-prenotazione-request";
import { PrenotazioneResponse } from "./prenotazione-response";

@Injectable({
    providedIn: "root",
})
export class PrenotazioneService {
    constructor(private http: HttpClient) {}

    getPrenotazione(id: number): Observable<BaseResponse<Prenotazione>> {
        const URI = `${environment.API_URL}/prenotazione/${id}`;

        return this.http.get<BaseResponse<Prenotazione>>(URI);
    }

    createPrenotazione(
        nuovaPrenotazione: NuovaPrenotazioneRequest
    ): Observable<BaseResponse<PrenotazioneResponse>> {
        const URI = `${environment.API_URL}/prenotazione`;

        return this.http.post<BaseResponse<PrenotazioneResponse>>(
            URI,
            nuovaPrenotazione
        );
    }

    cercaPrenotazioniStabilimento(
        idStabilimento: number,
        dataInizio: string,
        dataFine: string
    ): Observable<BaseResponse<Prenotazione[]>> {
        const URI = `${environment.API_URL}/prenotazione/cerca`;

        return this.http.post<BaseResponse<Prenotazione[]>>(URI, {
            data_da: dataInizio,
            data_a: dataFine,
            id_stabilimento: idStabilimento,
        });
    }

    cercaPrenotazioniOmbrellone(
        idOmbrellone: number,
        dataInizio: string,
        dataFine: string
    ): Observable<BaseResponse<Prenotazione[]>> {
        const URI = `${environment.API_URL}/prenotazione/cerca/${idOmbrellone}`;

        return this.http.post<BaseResponse<Prenotazione[]>>(URI, {
            data_da: dataInizio,
            data_a: dataFine,
        });
    }

    checkInPrenotazione(idOmbrellone: number): Observable<BaseResponse<any>> {
        const URI = `${environment.API_URL}/prenotazione/${idOmbrellone}/checkin`;

        return this.http.patch<BaseResponse<any>>(URI, {});
    }
}
