import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ServizioStabilimento } from "../../models/servizio-stabilimento";
import { BaseResponse } from "../base-response";

@Injectable({
    providedIn: "root",
})
export class ServizioStabilimentoService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<BaseResponse<ServizioStabilimento[]>> {
        const URI = `${environment.API_URL}/servizio`;

        return this.http.get<BaseResponse<ServizioStabilimento[]>>(URI);
    }
}
