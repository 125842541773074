<mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav #sidenav mode="over" role="navigation">
        <mat-nav-list>
            <div style="padding: 8px">
                <img src="assets/banner.jpg" alt="" width="260px" />
            </div>
            <h3 class="text-center d-block d-sm-none" style="color:#058fb2">
                {{ nomeStabilimento }}
            </h3>
        </mat-nav-list>
        <mat-nav-list>
            <h2 matSubheader>Dashboard</h2>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">dashboard</mat-icon>
                Dashboard
            </a>

            <h2 matSubheader>Situazione spiaggia</h2>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/situazione-spiaggia']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">calendar_today</mat-icon>
                Situazione spiaggia
            </a>

            <!-- <h2 matSubheader>Lista prenotazioni</h2>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/lista-prenotazioni']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">list</mat-icon>
                Lista prenotazioni
            </a> -->

            <h2 matSubheader>Stabilimento</h2>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/dettagli']"
            >
                <mat-icon class="nav-list-icon">details</mat-icon>
                Dettagli
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/mappa']"
            >
                <mat-icon class="nav-list-icon">waves</mat-icon>
                Mappa
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/immagini']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">image</mat-icon>
                Immagini
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/servizi']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">restaurant</mat-icon>
                Servizi
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/prezzi']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">euro</mat-icon>
                Prezzi
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/planner']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">view_comfy</mat-icon>
                Planner
            </a>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/stabilimento/clienti']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">person</mat-icon>
                Clienti
            </a>

            <h2 matSubheader>Impostazioni</h2>
            <a
                mat-list-item
                (click)="sidenav.close()"
                [routerLink]="['/impostazioni/configura-spiaggia']"
                routerLinkActive="list-item-active"
            >
                <mat-icon class="nav-list-icon">settings</mat-icon>
                Configura spiaggia
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
        <mat-toolbar color="primary">
            <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <img src="assets/logo.png" alt="" height="30px" />
            <span class="example-spacer"></span>
            <span class="me-3 d-none d-sm-block">{{ nomeStabilimento }}</span>
            <div
                [attr.data-initials]="initial"
                [matMenuTriggerFor]="menu"
            ></div>
            <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Profile</span>
                </button> -->
                <button mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Sign out</span>
                </button>
            </mat-menu>
        </mat-toolbar>
        <div class="container-fluid mt-2">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
