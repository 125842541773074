import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { lastValueFrom } from "rxjs";
import { AccountStorageService } from "../core/services";
import { AuthService } from "../modules/account/services/auth.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    MAX_TVTL = 65 * 60 * 1000; // 65 minuti

    constructor(
        private router: Router,
        private accountStorageService: AccountStorageService,
        private authService: AuthService
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot //: // | Observable<boolean | UrlTree> // | Promise<boolean | UrlTree> // | boolean // | UrlTree
    ) {
        const account = this.accountStorageService.get();
        const isLoggedIn = account?.token;

        if (isLoggedIn) {
            const tokenValidationTimeLeft = this.getTokenValidationTimeLeft(
                account?.token
            );

            if (tokenValidationTimeLeft <= this.MAX_TVTL) {
                // il token sta per scadere, eseguo il refresh

                const logout = await lastValueFrom(
                    this.authService.refreshToken()
                )
                    .then((response) => {
                        this.accountStorageService.setToken(response.token);

                        this.accountStorageService.setUser(
                            response.user.id,
                            response.user.email,
                            response.user.nome,
                            response.user.cognome
                        );

                        return false;
                    })
                    .catch((err) => {
                        console.error(err);

                        return true;
                    });

                if (logout) {
                    this.accountStorageService.logout();

                    this.router.navigate(["account/login"]);
                }
            }

            return true;
        } else {
            this.router.navigate(["account/login"]);

            return false;
        }
    }

    getTokenValidationTimeLeft(token: string): number {
        const payload = JSON.parse(atob(token.split(".")[1]));

        const expirationTime = (payload.exp as number) * 1000; // convert in millisecond

        const now = new Date().getTime();

        const tokenValidationTimeLeft = expirationTime - now;

        return tokenValidationTimeLeft;
    }
}
